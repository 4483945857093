import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import Background from '@/components/atoms/Background';
import Typography from '@/components/atoms/Typography';
import Button from '@/components/atoms/Button';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';
import Link from '@/components/atoms/Link';

export interface ContactBoxProps {
  background?: ImageDataType;
  title?: React.ReactNode;
  content?: React.ReactNode;
  button?: {
    text: React.ReactNode;
    link: string;
  };
  overlay?: number;
}

const BoxBackground = styled(Background)`
  border-radius: 10px;
`;
const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(2)};
  ${mq('xxs')} {
    padding-top: ${({ theme }) => theme.spacing(4.5)};
    padding-bottom: ${({ theme }) => theme.spacing(4.5)};
    padding-left: ${({ theme }) => theme.spacing(5.5)};
    padding-right: ${({ theme }) => theme.spacing(5.5)};
  }

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(7.5)};
    padding-bottom: ${({ theme }) => theme.spacing(7.5)};
    padding-left: ${({ theme }) => theme.spacing(8)};
    padding-right: ${({ theme }) => theme.spacing(8)};
  }
`;
const Title = styled(Typography)`
  text-align: center;
`;
const Content = styled(Typography)`
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
  font-size: 1.5rem;
  ${mq('lg')} {
    font-size: 1.8rem !important;
  }
`;
const ContactButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2.5)};
  font-size: 1.7rem;
`;

const ContactBox = ({
  background,
  title,
  content,
  button,
  overlay = 0.6,
}: ContactBoxProps): JSX.Element => {
  return (
    <BoxBackground overlay={overlay} background={background}>
      <TextContainer>
        <Title variant="h3" color="white">
          {title}
        </Title>
        <Content variant="textMd" color="white">
          {content}
        </Content>
        <ContactButton as={Link} {...{ to: `${button?.link}` }}>
          {button?.text}
        </ContactButton>
      </TextContainer>
    </BoxBackground>
  );
};

// export const query = graphql`
//   fragment ContactBox on PrismicContactBoxDataType {
//     contact_box_background {
//       url
//       alt
//     }
//     contact_box_section_title {
//       raw
//     }
//     contact_box_content {
//       raw
//     }
//   }
// `;

export default ContactBox;
