import React, { HTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { css, Theme, useTheme } from '@emotion/react';
import Img from '@/components/atoms/Img';

import mq from '@/styles/mq';

import { OptionalImageProps } from '@/components/atoms/Img/Img';
import BackgroundDataType from '@/types/data/background';

export interface BackgroundProps extends HTMLAttributes<HTMLDivElement> {
  /**
   The url and alt of the desktop and mobile background
   */
  background?: BackgroundDataType;
  /**
   Content shown above the background
   */
  children?: React.ReactNode;
  /**
   Additional props to add to the Img component
   */
  imageProps?: OptionalImageProps;
  /**
   Add the a black overlay above the background with the value specified as the opacity => rgba(0, 0, 0, X)
   */
  overlay?: number;
}

const BackgroundRoot = styled('div')`
  position: relative;
  overflow: hidden;
`;
const BackgroundStyled = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zIndex.background};
`;
const DesktopBackground = styled(BackgroundStyled)`
  display: none;

  ${mq('lg')} {
    display: block;
  }
`;
const MobileBackground = styled(BackgroundStyled)`
  display: ${({ src }) => (src ? 'block' : 'none')};

  ${mq('lg')} {
    display: none;
  }
`;
const Content = styled('div')`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.content};
  width: 100%;
  height: 100%;
`;

const showDesktopOnMobile = css`
  display: block;
`;
const setOverlay = (theme: Theme, overlay: number) => css`
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${theme?.zIndex?.overlay};
    background-color: rgba(17, 17, 17, ${overlay});
  }
`;

/**
 <h3>This component either displays a desktop or a mobile background from prismic depending on view size</h3>
 <p>It handles the image with the Img component using react-imgix</p>
 **/
const Background = ({
  background,
  children,
  imageProps,
  overlay = 0.4,
  ...rest
}: BackgroundProps): JSX.Element => {
  const { url, alt, mobileUrl, mobileAlt } = background || {};
  const theme = useTheme();

  return (
    <BackgroundRoot
      css={overlay ? setOverlay(theme, overlay) : undefined}
      {...rest}
    >
      {url && (
        <DesktopBackground
          css={!mobileUrl ? showDesktopOnMobile : undefined}
          src={url}
          alt={alt || ''}
          sizes="100vw"
          fit="cover"
          {...imageProps}
        />
      )}
      {mobileUrl && (
        <MobileBackground
          src={mobileUrl}
          alt={mobileAlt || ''}
          sizes="100vw"
          fit="cover"
          {...imageProps}
        />
      )}
      <Content>{children}</Content>
    </BackgroundRoot>
  );
};

export default Background;
